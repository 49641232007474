.page-list {
    display: flex;
}

.page-list p {
    padding-left: 10px;
}

.ArticlePage-text {
    max-width: 300px;
    padding: 50px 100px;
    margin: 50px auto;
    box-shadow: 1px 1px 20px 0px #848484;
}

.article-date {
    padding-bottom: 30px;
}

.back-to-home {
    padding-top: 80px;
    text-align: center;
}

.back-to-home a {
    color: #000000;
    text-decoration: none;
    border: 1px solid #000000;
    padding: 20px;
    transition: all .3s;
}

.back-to-home a:hover {
    box-shadow: 5px 7px #000000;
}