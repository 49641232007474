.heder-img {
    display: block;
    width: 100%;
    height: auto;
}

.home-nav {
    background-color: #e0e0e0;
}

.home-nav ul {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 20px 0;
}

.home-nav ul li {
    padding: 0 5px;
}

.home-nav ul li a {
    padding: 20px 40px;
    text-decoration: none;
    color: #000;
    cursor: pointer;
    background-color: #e0e0e0;
    border-left: 1px solid #000;

    &:last-child {
        border-right: 1px solid #000;
    }

    &:hover {
        opacity: .5;
    }
}

@media screen and (max-width: 480px) {

    .heder-img {
        width: 100%;
        height: 50vh;
        object-fit: cover;
    }

    .home-nav,
    .home-nav ul,
    .home-nav ul li,
    .home-nav ul li a {
        padding: 0;
        margin: 0;
    }

    .home-nav ul {
        padding: 10px 0;
    }

    .home-nav ul li a {
        padding: 10px 20px;
    }
}