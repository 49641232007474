.page-list {
    margin: 20px;
    list-style: none;
}

.page-list li::after {
    padding-left: 10px;
    content: ">";
}

.Loading {
    text-align: center;
    height: 1000px;
}

.cloud_svg {
    width: 100px;
    height: auto;
}