.article a {
    text-decoration: none;
    color: #000;
}

.article-text {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 20px;
}

.article-div {
    margin: 20px;
    transition: all .3s;
    max-width: 300px;
}

.article-div:hover {
    opacity: .7;
    cursor: pointer;
}

.article-img {
    display: block;
    width: 300px;
    height: auto;
}

.date-clock {
    display: flex;
}

.date {
    font-size: 12px;
    padding: 5px 0 10px 0;
}

.clock {
    display: block;
    height: 15px;
    width: 15px;
    opacity: .8;
    padding: 5px 5px 10px 0;
}

.read-more {
    text-align: center;
    margin: 20px;
}

.searchBar {
    display: grid;
    text-align: right;
    padding-right: 20px;
    justify-content: end;

    position: relative;
}

.searchContents {
    display: flex;
    flex-flow: column;
    list-style: none;
    align-items: flex-end;

    position: absolute;
    /* 検索結果を絶対位置で表示 */
    top: 100%;
    /* 検索バーの下に表示 */
    left: 0;
    right: 0;
    z-index: 1000;
    /* 他の要素の上に表示 */
    list-style: none;
    /* リストスタイルを非表示 */
    padding: 0 20px 0 0;
    margin: 0;
}

.searchContentsLi {
    text-align: left;
    padding: 5px 10px;
    background-color: #ffffff;
    border: 1px solid #7a7a7a;
    width: 220px;
}

.CanNotSearch {
    font-size: .9em;
}

.searchForm input {
    font: 400 16px/1;
    font-size: 16px;
    padding: .75em .25em .75em 3em;
    border-radius: .5em;
    border-radius: .5rem;
    border: 1px solid transparent;
    background: no-repeat left .8em center / 1.5em url(../img/svg/MagnifyingGlass.svg);
    cursor: pointer;
    width: 2.5em;
    transition: width, border-color;
    transition-duration: .4s;
}

.searchForm input:focus {
    cursor: auto;
    width: 240px;
    border-color: #ccc;
}